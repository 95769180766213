import * as React from "react";
import { SearchInput, Pagination, BooleanField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';

const PostPagination = () => <Pagination rowsPerPageOptions={[50, 100]} />;

const postFilters = [
    <SearchInput source="q" alwaysOn />,
];
export const EquipmentTypesList = props => (
    <List {...props} pagination={<PostPagination />} filters={postFilters} perPage={100}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Display" />
            <TextField source="description" />
        </Datagrid>
    </List>
);



export const EquipmentTypesCreate = props => (
    <Create title="Create a new Dispatch Center" {...props}>
        <SimpleForm>

            <TextInput source="name" label="Display" validate={required()}/>
            <TextInput source="description" />

        </SimpleForm>
    </Create>
);


export const EquipmentTypesEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
                        <TextInput source="name" label="Display" validate={required()}/>
                        <TextInput source="description" />
        </SimpleForm>
    </Edit>
);
