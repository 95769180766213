import * as React from "react";
import { Card, CardContent, CardHeader,Typography } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
const theme = createTheme({
  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontWeight: 500,
    },
    button: {
      fontStyle: 'italic',
    },
  },
});
export default () => (
  <>
    <Card>
        <CardHeader title="Manage the settings for GServe" />
        <CardContent>
        <ThemeProvider theme={theme}>
<Typography variant="subtitle1">You can customize the following items and make the Work Order section behaves in a way that is most suited for your business.</Typography>
<ol>
<li><Typography variant="body1">Asset Types</Typography>
<Typography variant="subtitle1">You can create asset types, set services and create checklists for them here. Asset types are required if you are going to be doing Work Orders on asset and wish to add it to the system and track them. You need these types defined if you want your Techs to add assets to the locations or while completing the work order.</Typography>
</li>
<li><Typography variant="body1">Dispatch Centers</Typography>
<Typography variant="subtitle1">You can view a list of Dispatch Centers, users who are members and areas they service.</Typography>
</li>
<li><Typography variant="body1">Catalog</Typography>
<Typography variant="subtitle1">List of items that can be consumed in a Work Order and that showup as line items in the Invoice. Parts make up the most of the Catalog.</Typography>
</li>
<li><Typography variant="body1">Catalog Categories</Typography>
<Typography variant="subtitle1">Categories that are used for the classification of Catalog Items.</Typography>
</li>
<li><Typography variant="body1">Quantity Pricing</Typography>
<Typography variant="subtitle1">Parts pricing for parts when purchased in quantities like 10+, 25+, etc.</Typography>
</li>
<li><Typography variant="body1">Sales Tax Codes</Typography>
<Typography variant="subtitle1">Select a Tax Code to specify if the Customer is taxable or not. For example, in some states government and religious organizations are non-taxable.</Typography>
</li>
<li><Typography variant="body1">Invoice Status</Typography>
<Typography variant="subtitle1">Different statuses an Invoice can be as part of the Work Order life cycle.</Typography>
</li>
<li><Typography variant="body1">Tax Rates</Typography>
<Typography variant="subtitle1">Specify the default tax rate that will appear on invoices.</Typography>
</li>
<li><Typography variant="body1">Locations</Typography>
<Typography variant="subtitle1">Customer sites that are subscribed for a service plan.</Typography>
</li>
<li><Typography variant="body1">Service Packages</Typography>
<Typography variant="subtitle1">You can add new service packages or customize the existing one.</Typography>
</li>
<li><Typography variant="body1">Repair Categories</Typography>
<Typography variant="subtitle1">Categorize the work that needs to be performed as part of a Work Order.</Typography>
</li>
<li><Typography variant="body1">Repair Codes</Typography>
<Typography variant="subtitle1">Codes to identify the work that is being performed.</Typography>
</li>
<li><Typography variant="body1">Service Teams</Typography>
<Typography variant="subtitle1">You can view a list Teams, Techs who are members and areas they service.</Typography>
</li>
<li><Typography variant="body1">Service Zones</Typography>
<Typography variant="subtitle1">You can manage Service Zones, including corresponding Service Teams and Dispatch Centers.</Typography>
</li>
<li><Typography variant="body1">Users</Typography>
<Typography variant="subtitle1">Users in the system (Customers, Managers, Technicians, etc.)</Typography>
</li>
<li>
<Typography variant="body1">Cancel Reasons</Typography>
<Typography variant="subtitle1">You can add different reasons for canceling Work Orders. When you or your Tech cancels a Work Order they can pick an appropriate reason from the list you define here.</Typography>
</li>
<li>
<Typography variant="body1">Work Order Categories</Typography>
<Typography variant="subtitle1">You can add different reasons for canceling Work Orders. When you or your Tech cancels a Work Order they can pick an appropriate reason from the list you define here.</Typography>
</li>
<li>
<Typography variant="body1">Work Order Category Items</Typography>
<Typography variant="subtitle1">You can add different reasons for canceling Work Orders. When you or your Tech cancels a Work Order they can pick an appropriate reason from the list you define here.</Typography>
</li>
<li><Typography variant="body1">Flag Reasons</Typography>
<Typography variant="subtitle1">You can add different reasons for flagging a Work Order. When you or your Techs flag a Work Order they can pick appropriate reasons from the list you define here.</Typography>
</li>
<li><Typography variant="body1">On-Hold Reasons</Typography>
<Typography variant="subtitle1">You can add different reasons for putting a Work Order on hold. When you or your Tech puts a Work Order on hold you can pick an appropriate reason from the list you define here.</Typography>
</li>
<li>
<Typography variant="body1">Priorities</Typography>
<Typography variant="subtitle1">You can customize how you would like to call your priorities and set overdue hours for each of the predefined priorities here.</Typography>
</li>
<li><Typography variant="body1">Work Order Status</Typography>
<Typography variant="subtitle1">Different statuses a Work Order can be as part of the Work Order life cycle.</Typography>
</li>
<li><Typography variant="body1">Work Order Types</Typography>
<Typography variant="subtitle1">You can enter different types Work Order types here. This will help to you categorize the Work Orders in reporting.</Typography>
</li>
</ol>


</ThemeProvider>
        </CardContent>
    </Card>
    </>
);
