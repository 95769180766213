import * as React from "react";
import { ReferenceField, SelectInput, BooleanField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';

export const DispatchCentersList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Display" />
            <TextField source="description" />
            <BooleanField source="mobile" label="Is Van?"/>
            <ReferenceField label="Owner" source="owner" reference="users" alwaysOn>
                <TextField source="email" />
            </ReferenceField>
            <FunctionField label="Address" render={record => `${record.street1}, ${record.city}, ${record.state} - ${record.zip}`} />
        </Datagrid>
    </List>
);



export const DispatchCentersCreate = props => (
    <Create title="Create a new Dispatch Center" {...props}>
        <SimpleForm>

            <TextInput source="name" label="Display" validate={required()}/>
            <TextInput source="description" validate={required()}/>
            <BooleanInput source="mobile" label="Is it a Van?" />
            <ReferenceInput label="Owner" source="owner" reference="users" alwaysOn>
                <SelectInput optionText="email" label="Owner"/>
            </ReferenceInput>

            <TextInput source="street1" label="Address" />
            <TextInput source="state"  />
            <TextInput source="city" />
            <TextInput source="zip"  />

        </SimpleForm>
    </Create>
);


export const DispatchCentersEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
                        <TextInput source="name" label="Display" validate={required()}/>
                        <TextInput source="description" validate={required()}/>
                        <BooleanInput source="mobile" label="Is it a Van?" />
                        <ReferenceInput label="Owner" source="owner" reference="users" alwaysOn>
                            <SelectInput optionText="email" label="Owner"/>
                        </ReferenceInput>

                        <TextInput source="street1" label="Address"  />
                        <TextInput source="state"  />
                        <TextInput source="city"  />
                        <TextInput source="zip"  />
        </SimpleForm>
    </Edit>
);
