import * as React from "react";
import { SearchInput, SelectInput, NumberField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';

const postFilters = [
    <SearchInput source="q" alwaysOn />,
];
export const CompanyList = props => (
    <List {...props} filters={postFilters}>
        <Datagrid rowClick="edit">
        <TextField label="Name" source="companyName" />
            <FunctionField label="Address" render={record => `${record.legalStreet1}, ${record.legalCity}, ${record.legalState} - ${record.legalZip}`} />
            <TextField source="contact" />
            <EmailField source="email" />
            <TextField source="phone" />
        </Datagrid>
    </List>
);
