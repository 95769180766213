import * as React from "react";
import { SearchInput, ReferenceField,SelectInput, NumberField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';

const postFilters = [
    <SearchInput source="q" alwaysOn />,
];
export const UserList = props => (
    <List {...props} filters={postFilters}>
        <Datagrid rowClick="edit">
            <FunctionField label="FullName" render={record => `${record.firstName} ${record.lastName}`} />
            <EmailField source="email" />
            <TextField source="phone" />
            <TextField source="role" />

            <ReferenceField label="Location" source="company" reference="companies" alwaysOn>
              <TextField source="companyName" />
            </ReferenceField>

            <ReferenceField label="Team" source="serviceTeam" reference="serviceteams" alwaysOn>
              <TextField source="name" />
            </ReferenceField>
        </Datagrid>
    </List>
);



export const UserCreate = props => (
    <Create title="Create a new User" {...props}>
        <SimpleForm>
        <TextInput source="name" label="NickName" validate={required()}/>
        <TextInput source="firstName" validate={required()}/>
        <TextInput source="middleName" />
        <TextInput source="lastName" validate={required()}/>
        <TextInput source="email" validate={required()}/>
        <TextInput source="phone" validate={required()}/>
        <TextInput source="companies" validate={required()}/>
        <TextInput source="address" />
        <TextInput source="city" />
        <TextInput source="state" />
        <TextInput source="zip" />
        <TextInput source="role" />
        </SimpleForm>
    </Create>
);


export const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextField disabled label="Id" source="id" />
            <EmailField source="email" validate={required()}/>
                    <TextInput source="firstName" validate={required()}/>
                    <TextInput source="lastName" validate={required()}/>
                    <TextInput source="phone" validate={required()}/>

                                        <ReferenceInput label="Location" source="company" reference="companies">
                                            <SelectInput optionText="companyName" />
                                        </ReferenceInput>
                    <TextInput source="address" />
                    <TextInput source="city" />
                    <TextInput source="state" />
                    <TextInput source="zip" />
                    <SelectInput source="role"  validate={required()} choices={[
                        { id: 'Customer', name: 'Customer' },
                        { id: 'Manager', name: 'Manager' },
                        { id: 'Technician', name: 'Technician' },
                    ]} />


                                        <ReferenceInput label="Service Team" source="serviceTeam" reference="serviceteams">
                                            <SelectInput optionText="name" />
                                        </ReferenceInput>
        </SimpleForm>
    </Edit>
);
