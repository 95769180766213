import * as React from "react";
import { SelectInput, ReferenceField, BooleanField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';

export const WorkzonesList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Display" />
            <TextField source="description" />

                        <ReferenceField label="Dispatch Center" source="dispatchCenter" reference="dispatchcenters" alwaysOn>
                          <TextField source="name" />
                        </ReferenceField>

                                    <ReferenceField label="Primary Service Team" source="serviceTeam" reference="serviceteams" alwaysOn>
                                      <TextField source="name" />
                                    </ReferenceField>
        </Datagrid>
    </List>
);



export const WorkzonesCreate = props => (
    <Create title="Create a new Dispatch Center" {...props}>
        <SimpleForm>

            <TextInput source="name" label="Display" validate={required()}/>
            <TextInput source="description" />

            <ReferenceInput label="Dispatch Center" source="dispatchCenter" reference="dispatchcenters" alwaysOn>
              <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput label="Primary Service Team" source="serviceTeam" reference="serviceteams" alwaysOn>
              <SelectInput optionText="name" />
            </ReferenceInput>

        </SimpleForm>
    </Create>
);


export const WorkzonesEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
                        <TextInput source="name" label="Display" validate={required()}/>
                        <TextInput source="description" />

                        <ReferenceInput label="Dispatch Center" source="dispatchCenter" reference="dispatchcenters" alwaysOn>
                          <SelectInput optionText="name" />
                        </ReferenceInput>

                        <ReferenceInput label="Primary Service Team" source="serviceTeam" reference="serviceteams" alwaysOn>
                          <SelectInput optionText="name" />
                        </ReferenceInput>
        </SimpleForm>
    </Edit>
);
