import * as React from "react";
import { Pagination, ReferenceField, BooleanField, SelectInput, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';
const PostPagination = () => <Pagination rowsPerPageOptions={[50, 100]} />;
export const WorkorderCategoryItemsList = props => (
    <List {...props} pagination={<PostPagination />} perPage={100}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Display" />
            <TextField source="description" />
            <TextField source="seq" label="Sequence"/>

            <ReferenceField label="Workorder Category" source="workorderCategory" reference="workordercategories" alwaysOn>
                <TextField source="name" />
            </ReferenceField>
                                <ReferenceField label="Service Package" source="servicePackage" reference="servicepackages" alwaysOn>
                                    <TextField source="name" />
                                </ReferenceField>

        </Datagrid>
    </List>
);



export const WorkorderCategoryItemsCreate = props => (
    <Create title="Create a new Work Order" {...props}>
        <SimpleForm>

            <TextInput source="name" label="Display" validate={required()}/>
            <TextInput source="description" />
            <TextInput source="seq" label="Sequence"/>
                                <ReferenceInput label="Service Package" source="servicePackage" reference="servicepackages" alwaysOn>
                                    <SelectInput optionText="name" />
                                </ReferenceInput>

        <ReferenceInput label="Work Order Category" source="workorderCategory" reference="workordercategories" alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>
        </SimpleForm>
    </Create>
);


export const WorkorderCategoryItemsEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
                        <TextInput source="name" label="Display" validate={required()}/>
                        <TextInput source="description" />
                        <TextInput source="seq" label="Sequence"/>
                                            <ReferenceInput label="Service Package" source="servicePackage" reference="servicepackages" alwaysOn>
                                                <SelectInput optionText="name" />
                                            </ReferenceInput>

                    <ReferenceInput label="Work Order Category" source="workorderCategory" reference="workordercategories" alwaysOn>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
        </SimpleForm>
    </Edit>
);
