import * as React from "react";
import { ReferenceManyField, DatagridConfigurable, ReferenceField, SelectInput, NumberField, SearchInput, Pagination, BooleanField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { useRecordContext } from 'react-admin';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, SingleFieldList, ChipField, SelectColumnsButton, FilterButton, ArrayField, CreateButton, ExportButton, ArrayInput, SimpleFormIterator, NumberInput
} from 'react-admin';
import { SavedQueriesList, FilterLiveSearch, FilterList, FilterListItem } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import MailIcon from '@mui/icons-material/MailOutline';
import CategoryIcon from '@mui/icons-material/LocalOffer';

const PostPagination = () => <Pagination rowsPerPageOptions={[50, 100, 200]} />;

const postFilters = [
    <SearchInput  source="q" alwaysOn />,
];

const TagsField = () => {
    const record = useRecordContext();
    // console.log(record);
    let tot = 0;
    record.inventory.map(item => (
        tot += item.qty
    ))
    return (
        <p>{tot}</p>
    )
};

const PostListActions = () => (
  <TopToolbar>
      <SelectColumnsButton />
      <FilterButton />
      <CreateButton />
      <ExportButton />
  </TopToolbar>
);
export const PostFilterSidebar = () => (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 200 }}>
        <CardContent>
            <SavedQueriesList />
            <FilterList label="Category" >
                <FilterListItem label="Parts" value={{ invoiceItemCategory: '330' }} />
                <FilterListItem label="Travel" value={{ invoiceItemCategory: '331' }} />
                <FilterListItem label="Labor" value={{ invoiceItemCategory: '332' }} />
                <FilterListItem label="Misc" value={{ invoiceItemCategory: '333' }} />
                <FilterListItem label="Adhoc" value={{ invoiceItemCategory: '431' }} />
                <FilterListItem label="Verifone Parts" value={{ invoiceItemCategory: '584' }} />
                <FilterListItem label="Wayne Parts" value={{ invoiceItemCategory: '618' }} />
                <FilterListItem label="Gilbarco Parts" value={{ invoiceItemCategory: '623' }} />
                <FilterListItem label="Red Jacket Parts" value={{ invoiceItemCategory: '20' }} />
                <FilterListItem label="James Adhoc Parts" value={{ invoiceItemCategory: '12' }} />
                <FilterListItem label="Hanging Hardware" value={{ invoiceItemCategory: '119' }} />
                <FilterListItem label="Back Office" value={{ invoiceItemCategory: '130' }} />
                <FilterListItem label="Turbine & Controllers" value={{ invoiceItemCategory: '125' }} />
                <FilterListItem label="Dispenser Filters" value={{ invoiceItemCategory: '145' }} />
                <FilterListItem label="Data & Power" value={{ invoiceItemCategory: '155' }} />
            </FilterList>
        </CardContent>
    </Card>
);
export const InvoiceItemsList = props => (
    <List {...props} pagination={<PostPagination />} filters={postFilters} perPage={50} actions={<PostListActions />} aside={<PostFilterSidebar />}>
        <DatagridConfigurable rowClick="edit">
            <TextField source="partNumber" />
            <NumberField source="listPrice" options={{ style: 'currency', currency: 'USD' }} label="List"/>
            <NumberField source="salePrice" options={{ style: 'currency', currency: 'USD' }} label="Sale"/>
            <NumberField source="customerPrice" options={{ style: 'currency', currency: 'USD' }} label="Customer"/>
            <TagsField label="Inventory"/>
                <TextField source="tier" />
            <ReferenceField label="Category" source="invoiceItemCategory" reference="invoiceitemcategories" alwaysOn>
                <TextField source="name" />
            </ReferenceField>
        </DatagridConfigurable>
    </List>
);



export const InvoiceItemsCreate = props => (
    <Create title="Create a new Invoice Item" {...props}>
        <SimpleForm>
                <TextInput source="partNumber" validate={required()}/>
                <TextInput source="description" />
                <TextInput source="listPrice" validate={required()}/>
                <TextInput source="salePrice" />
                <TextInput source="customerPrice" validate={required()}/>

                    <TextInput source="tier" />

                                <ReferenceInput label="Category" source="invoiceItemCategory" reference="invoiceitemcategories" alwaysOn>
                                    <SelectInput optionText="name" label="Category"/>
                                </ReferenceInput>
                                <ReferenceInput label="Tax" source="salesTaxCode" reference="salestaxcodes" alwaysOn>
                                    <SelectInput optionText="name" label="Tax"/>
                                </ReferenceInput>
                                <ReferenceInput label="Asset" source="asset" reference="equipmenttypes" alwaysOn>
                                    <SelectInput optionText="name" label="Asset"/>
                                </ReferenceInput>
                        <TextInput source="effDt" label="Effective Date" />
                        <BooleanInput source="sellOutOfStock" />
                        <BooleanInput source="wrma" label="WRMA"/>
                        <BooleanInput source="exchange"/>
                        <BooleanInput source="part"/>
                        <TextInput source="comments" />

        </SimpleForm>
    </Create>
);


export const InvoiceItemsEdit = (props) => (
    <Edit {...props}>
    <SimpleForm>
                <TextInput disabled label="Id" source="id" />
                        <TextInput source="partNumber" validate={required()}/>
                        <TextInput source="description" />
                        <TextInput source="listPrice" validate={required()}/>
                        <TextInput source="salePrice" label="Cost"/>
                        <TextInput source="customerPrice" />
                                            <TextInput source="tier" />

                                <ReferenceInput label="Category" source="invoiceItemCategory" reference="invoiceitemcategories" alwaysOn>
                                    <SelectInput optionText="name" label="Category"/>
                                </ReferenceInput>
                                <ReferenceInput label="Tax" source="salesTaxCode" reference="salestaxcodes" alwaysOn>
                                    <SelectInput optionText="name" label="Tax"/>
                                </ReferenceInput>
                                <ReferenceInput label="Asset" source="asset" reference="equipmenttypes" alwaysOn>
                                    <SelectInput optionText="name" label="Asset"/>
                                </ReferenceInput>
                        <TextInput source="effDt" label="Effective Date" />
                        <BooleanInput source="qtyPricing" />
                        <BooleanInput source="sellOutOfStock" />
                        <BooleanInput source="wrma" label="WRMA"/>
                        <BooleanInput source="exchange"/>
                        <BooleanInput source="part"/>
                        <BooleanInput source="serialRequired"/>
                        <TextInput source="comments" />
                        <TextInput source="qtyPricingData" />

            <ReferenceField source="qtyPricingData" reference="invoiceitemsqtypricing">
            Quantity Pricing: <br/>
                Part# : <TextField source="partNumber" /> <br/>
                Desc :     <TextField source="description" /> <br/>
                List Each : <NumberField source="listEach" options={{ style: 'currency', currency: 'USD' }} label="List Each"/> <br/>
                10+ : <NumberField source="plus10" options={{ style: 'currency', currency: 'USD' }} label="10+"/><br/>
                25+ : <NumberField source="plus25" options={{ style: 'currency', currency: 'USD' }} label="25+"/><br/>
                50+ : <NumberField source="plus50" options={{ style: 'currency', currency: 'USD' }} label="50+"/><br/>
                100+ : <NumberField source="plus100" options={{ style: 'currency', currency: 'USD' }} label="100+"/>
            </ReferenceField>

                        <ArrayInput source="inventory">
                            <SimpleFormIterator inline>
                                <ReferenceInput label="Dispatch Center" source="dispatchCenterId" reference="dispatchcenters" alwaysOn>
                                    <SelectInput optionText="name" label="Dispatch Center"/>
                                </ReferenceInput>
                                <NumberInput source="qty" helperText={false} />
                                <TextInput source="primaryLocation" />
                                <TextInput source="secondaryLocation" />
                            </SimpleFormIterator>
                        </ArrayInput>
        </SimpleForm>
    </Edit>
);
