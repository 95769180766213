import * as React from "react";
import { NumberField, ReferenceManyField, ReferenceField, SelectInput, BooleanField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';

export const RepairCategoriesList = props => (
    <List {...props}>
        <Datagrid rowClick="edit" sort={{field: 'seq', order: 'ASC'}}>
            <TextField source="name" label="Display" sortable={false}/>
            <TextField source="description" sortable={false}/>
            <TextField source="seq" label="Sequence" sortBy="seq"
                sortByOrder="DESC"/>

        </Datagrid>
    </List>
);



export const RepairCategoriesCreate = props => (
    <Create title="Create a new Work Order" {...props}>
        <SimpleForm>

            <TextInput source="name" label="Display" validate={required()}/>
            <TextInput source="description" />
            <TextInput source="seq" label="Sequence"/>
        </SimpleForm>
    </Create>
);


export const RepairCategoriesEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
                        <TextInput source="name" label="Display" validate={required()}/>
                        <TextInput source="description" />
                        <TextInput source="seq" label="Sequence"/>

      <ReferenceManyField label="Codes" reference="repaircodes" target="repairCategory" sort={{ field: 'seq', order: 'ASC' }}>
        <Datagrid>
        <TextField source="name" label="Display"/>
        <TextField source="seq" label="Sequence"/>
        </Datagrid>
      </ReferenceManyField>
        </SimpleForm>
    </Edit>
);
