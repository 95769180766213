import * as React from "react";
import { NumberInput, DatagridConfigurable, ReferenceField, SelectInput, NumberField, SearchInput, Pagination, BooleanField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, SelectColumnsButton, FilterButton, CreateButton, ExportButton
} from 'react-admin';

const PostPagination = () => <Pagination rowsPerPageOptions={[50, 100, 200]} />;

const postFilters = [
    <SearchInput  source="q" alwaysOn />,
];


const PostListActions = () => (
  <TopToolbar>
      <SelectColumnsButton />
      <FilterButton />
      <CreateButton />
      <ExportButton />
  </TopToolbar>
);

export const InvoiceItemsQtyPricingList = props => (
    <List {...props} pagination={<PostPagination />} filters={postFilters} perPage={100} actions={<PostListActions />}>
        <DatagridConfigurable rowClick="edit">
            <TextField source="partNumber" />
            <TextField source="description" />
            <NumberField source="listEach" options={{ style: 'currency', currency: 'USD' }} label="List Each"/>
            <NumberField source="plus10" options={{ style: 'currency', currency: 'USD' }} label="10+"/>
            <NumberField source="plus25" options={{ style: 'currency', currency: 'USD' }} label="25+"/>
            <NumberField source="plus50" options={{ style: 'currency', currency: 'USD' }} label="50+"/>
            <NumberField source="plus100" options={{ style: 'currency', currency: 'USD' }} label="100+"/>
        </DatagridConfigurable>
    </List>
);



export const InvoiceItemsQtyPricingCreate = props => (
    <Create title="Create a new Invoice Item" {...props}>
        <SimpleForm>
                <TextInput source="partNumber" validate={required()}/>
                <TextInput source="description" />
                <TextInput source="listPrice" validate={required()}/>
                <TextInput source="salePrice" />
                <TextInput source="invoiceItemCategory" label="Category"/>
                <TextInput source="asset" />
                <TextInput source="effDt"  label="Effective Date"/>
                <TextInput source="comments" />
        </SimpleForm>
    </Create>
);


export const InvoiceItemsQtyPricingEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
                    <TextInput source="partNumber" validate={required()}/>
                    <TextInput source="description" />
                    <NumberInput source="listEach" label="List Each"/>
                    <NumberInput source="plus10" label="10+"/>
                    <NumberInput source="plus25"  label="25+"/>
                    <NumberInput source="plus50" label="50+"/>
                    <NumberInput source="plus100"  label="100+"/>
        </SimpleForm>
    </Edit>
);
