import * as React from "react";
import { ReferenceManyField, ReferenceField, SelectInput, BooleanField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';

export const WorkorderCategoriesList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Display" sortable={false}/>
            <TextField source="description" sortable={false}/>
            <TextField source="seq" label="Sequence" sortByOrder="ASC"/>
                                <ReferenceField label="Service Package" source="servicePackage" reference="servicepackages" alwaysOn>
                                    <TextField source="name" />
                                </ReferenceField>

        </Datagrid>
    </List>
);



export const WorkorderCategoriesCreate = props => (
    <Create title="Create a new Work Order" {...props}>
        <SimpleForm>

            <TextInput source="name" label="Display" validate={required()}/>
            <TextInput source="description" />
            <TextInput source="seq" label="Sequence"/>

                    <ReferenceInput label="Service Package" source="servicePackage" reference="servicepackages" alwaysOn>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
        </SimpleForm>
    </Create>
);


export const WorkorderCategoriesEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
                        <TextInput source="name" label="Display" validate={required()}/>
                        <TextInput source="description" />
                        <TextInput source="seq" label="Sequence"/>

                                <ReferenceInput label="Service Package" source="servicePackage" reference="servicepackages" alwaysOn>
                                    <SelectInput optionText="name" />
                                </ReferenceInput>

              <ReferenceManyField label="Items" reference="workordercategoryitems" target="workorderCategory" sort={{ field: 'seq', order: 'ASC' }}>
                <Datagrid>
                <TextField source="name" label="Display"/>
                <TextField source="seq" label="Sequence"/>
                </Datagrid>
              </ReferenceManyField>
        </SimpleForm>
    </Edit>
);
