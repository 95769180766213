import * as React from "react";
import { BooleanField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, UrlField, ArrayInput, SimpleFormIterator, NumberInput, SelectInput, ReferenceField, AutocompleteInput, ReferenceArrayInput, AutocompleteArrayInput
} from 'react-admin';

export const PurchaseOrdersList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
        <TextField source="id" />
                <ReferenceField label="Category" source="supplierId" reference="suppliers" alwaysOn>
                    <TextField source="supplierName" />
                </ReferenceField>
            <FunctionField label="Delivery Address" render={record => `${record.street1}, ${record.city}, ${record.state} - ${record.zip}`} />
            <TextField source="status" />
            <TextField source="items.length" label="Items" />
        </Datagrid>
    </List>
);



export const PurchaseOrdersCreate = props => (
    <Create title="Create a new PO" {...props}>
        <SimpleForm>

                <ReferenceInput label="Supplier" source="supplierId" reference="suppliers" alwaysOn>
                    <SelectInput optionText="supplierName" label="Supplier"/>
                </ReferenceInput>
Delivery Address (please change the default address, if needed):
            <TextInput source="street1" label="Street" validate={required()} defaultValue="5352 N Irwindale Ave C"/>
            <TextInput source="city"  validate={required()} defaultValue="Irwindale"/>
            <TextInput source="state" validate={required()} defaultValue="CA"/>
            <TextInput source="zip" validate={required()} defaultValue="91706"/>
        </SimpleForm>
    </Create>
);


export const PurchaseOrdersEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
                    <ReferenceInput label="Supplier" source="supplierId" reference="suppliers" alwaysOn>
                        <SelectInput optionText="supplierName" label="Supplier"/>
                    </ReferenceInput>
    Delivery Address (please change the default address, if needed):
            <TextInput source="street1" label="Street" validate={required()}/>
            <TextInput source="city" />
            <TextInput source="state" validate={required()}/>
            <TextInput source="zip" validate={required()}/>

                        <ArrayInput source="items" label="Parts">
                            <SimpleFormIterator inline>
                                    <ReferenceInput label="Part" source="itemID" reference="invoiceitems">
                                        <AutocompleteInput optionText="partNumber" sx={{ width: 400 }}/>
                                    </ReferenceInput>
                                <NumberInput source="qty" helperText={false} />
                                <TextInput source="itemID1" label="Comments"/>
                            </SimpleFormIterator>
                        </ArrayInput>
        </SimpleForm>
    </Edit>
);
