// in src/MyMenu.js
import * as React from 'react';
import { Menu } from 'react-admin';

import BookIcon from '@mui/icons-material/Book';
import PeopleIcon from '@mui/icons-material/People';
import LabelIcon from '@mui/icons-material/Label';
import SubMenu from './submenu';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentationTwoTone';
import DomainIcon from '@material-ui/icons/DomainTwoTone';
import BuildIcon from '@material-ui/icons/BuildTwoTone';
import FlagIcon from '@material-ui/icons/FlagTwoTone';
import PausePresentationIcon from '@material-ui/icons/PausePresentationTwoTone';
import PriorityHighIcon from '@material-ui/icons/PriorityHighTwoTone';
import ListAltIcon from '@material-ui/icons/ListAltTwoTone';
import GroupIcon from '@material-ui/icons/GroupTwoTone';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayListTwoTone';
import ExploreIcon from '@material-ui/icons/ExploreTwoTone';
import CategoryIcon from '@material-ui/icons/CategoryTwoTone';
import ExtensionIcon from '@material-ui/icons/ExtensionTwoTone';
import LocationOnIcon from '@material-ui/icons/LocationOnTwoTone';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarmTwoTone';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevicesTwoTone';
import FormatListBulletedTwoToneIcon from '@material-ui/icons/FormatListBulletedTwoTone';
import FormatListNumberedTwoToneIcon from '@material-ui/icons/FormatListNumberedTwoTone';
import DnsTwoToneIcon from '@material-ui/icons/DnsTwoTone';
import LocalAtmTwoToneIcon from '@material-ui/icons/LocalAtmTwoTone';
import ReceiptIcon from '@material-ui/icons/ReceiptTwoTone';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOnTwoTone';
import LocalGasStation from '@material-ui/icons/LocalGasStationTwoTone';
import StoreTwoToneIcon from '@mui/icons-material/StoreTwoTone';
import { SuppliersList, SuppliersCreate, SuppliersEdit} from './suppliers';
import { UserList, UserCreate, UserEdit} from './users';
import { CompanyList} from './companies';
import { CancelReasonList, CancelReasonCreate, CancelReasonEdit} from './cancelreason';
import { DispatchCentersList, DispatchCentersCreate, DispatchCentersEdit} from './dispatchcenters';
import { EquipmentTypesList, EquipmentTypesCreate, EquipmentTypesEdit} from './equipmenttypes';
import { FlagReasonsList, FlagReasonsCreate, FlagReasonsEdit} from './flagreasons';
import { OnholdReasonsList, OnholdReasonsCreate, OnholdReasonsEdit} from './onholdreasons';
import { InvoiceStatusList, InvoiceStatusCreate, InvoiceStatusEdit} from './invoicestatus';
import { InvoiceItemsList, InvoiceItemsCreate, InvoiceItemsEdit} from './invoiceitems';
import { InvoiceItemsQtyPricingList, InvoiceItemsQtyPricingCreate, InvoiceItemsQtyPricingEdit} from './invoiceitemsqtypricing';
import { InvoiceItemCategoriesList, InvoiceItemCategoriesCreate, InvoiceItemCategoriesEdit} from './invoiceitemcategories';
import { PrioritiesList, PrioritiesCreate, PrioritiesEdit} from './priorities';
import { SalesTaxCodesList, SalesTaxCodesCreate, SalesTaxCodesEdit} from './salestaxcodes';
import { SalesTaxList, SalesTaxCreate, SalesTaxEdit} from './salestaxitems';
import { ServicePackagesList, ServicePackagesCreate, ServicePackagesEdit} from './servicepackages';
import { ServiceTeamsList, ServiceTeamsCreate, ServiceTeamsEdit} from './serviceteams';
import { WorkorderCategoriesList, WorkorderCategoriesCreate, WorkorderCategoriesEdit} from './workordercategories';
import { RepairCategoriesList, RepairCategoriesCreate, RepairCategoriesEdit} from './repaircategories';
import { RepairCodesList, RepairCodesCreate, RepairCodesEdit} from './repaircodes';
import { WorkorderCategoryItemsList, WorkorderCategoryItemsCreate, WorkorderCategoryItemsEdit} from './workordercategoryitems';
import { WorkorderStatusList, WorkorderStatusCreate, WorkorderStatusEdit} from './workorderstatus';
import { WorkorderTypesList, WorkorderTypesCreate, WorkorderTypesEdit} from './workordertypes';
import { WorkzonesList, WorkzonesCreate, WorkzonesEdit} from './workzones';
import { PaymentModeList, PaymentModeCreate, PaymentModeEdit} from './paymentmodes';
import { PaymentPurposeList, PaymentPurposeCreate, PaymentPurposeEdit} from './paymentpurpose';
import { PaymentStatusList, PaymentStatusCreate, PaymentStatusEdit} from './paymentstatus';
import { PaymentTermsList, PaymentTermsCreate, PaymentTermsEdit} from './paymentterms';
import { SubscriptionStatusList, SubscriptionStatusCreate, SubscriptionStatusEdit} from './subscriptionstatus';
import PersonIcon from '@material-ui/icons/PersonTwoTone';
import SettingsApplicationsTwoToneIcon from '@material-ui/icons/SettingsApplicationsTwoTone';
import BookmarksTwoToneIcon from '@material-ui/icons/BookmarksTwoTone';
import BallotTwoToneIcon from '@material-ui/icons/BallotTwoTone';
import MenuBookTwoToneIcon from '@material-ui/icons/MenuBookTwoTone';
import DoneAllTwoToneIcon from '@material-ui/icons/DoneAllTwoTone';
import FilterNoneTwoToneIcon from '@material-ui/icons/FilterNoneTwoTone';
import MoneyTwoToneIcon from '@material-ui/icons/MoneyTwoTone';
import MiscellaneousServicesTwoToneIcon from '@mui/icons-material/MiscellaneousServicesTwoTone';
import StyleTwoToneIcon from '@mui/icons-material/StyleTwoTone';
import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';
import ShoppingBagTwoToneIcon from '@mui/icons-material/ShoppingBagTwoTone';
import { InventoryList, InventoryCreate, InventoryEdit} from './inventory';
import WidgetsTwoToneIcon from '@material-ui/icons/WidgetsTwoTone';
import { PurchaseOrdersList, PurchaseOrdersCreate, PurchaseOrdersEdit} from './purchaseorders';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';

export const MyMenu = (props) => (
<Menu {...props}>
  <Menu.DashboardItem />
  <SubMenu primaryText="Inventory" leftIcon={<BallotTwoToneIcon />}>
    <Menu.Item primaryText="Asset Types" to="equipmenttypes"  leftIcon={<ImportantDevicesIcon/>} list={EquipmentTypesList} create={EquipmentTypesCreate} edit={EquipmentTypesEdit} />
    <Menu.Item primaryText="Dispatch Centers" to="dispatchcenters"  leftIcon={<DomainIcon/>} list={DispatchCentersList} create={DispatchCentersCreate} edit={DispatchCentersEdit} />
    <Menu.Item primaryText="Inventory" to="inventory"  leftIcon={<WidgetsTwoToneIcon/>} list={InventoryList} create={InventoryCreate} edit={InventoryEdit} />
    <Menu.Item primaryText="Purchase Orders" to="purchaseorders"  leftIcon={<ShoppingCartTwoToneIcon/>} list={PurchaseOrdersList} create={PurchaseOrdersCreate} edit={PurchaseOrdersEdit} />
    <Menu.Item primaryText="Suppliers" to="suppliers"  leftIcon={<StoreTwoToneIcon/>} list={SuppliersList} create={SuppliersCreate} edit={SuppliersEdit} />
  </SubMenu>
  <SubMenu primaryText="Invoices" leftIcon={<ReceiptIcon />}>
    <Menu.Item primaryText="Catalog" to="invoiceitems"  leftIcon={<MenuBookTwoToneIcon/>} list={InvoiceItemsList} create={InvoiceItemsCreate} edit={InvoiceItemsEdit} />
    <Menu.Item primaryText="Catalog Categories" to="invoiceitemcategories"  leftIcon={<CategoryIcon/>} list={InvoiceItemCategoriesList} create={InvoiceItemCategoriesCreate} edit={InvoiceItemCategoriesEdit} />
    <Menu.Item primaryText="Qty Pricing" to="invoiceitemsqtypricing"  leftIcon={<MoneyTwoToneIcon/>} list={InvoiceItemsQtyPricingList} create={InvoiceItemsQtyPricingCreate} edit={InvoiceItemsQtyPricingEdit} />
    <Menu.Item primaryText="Sales Tax Codes" to="salestaxcodes"  leftIcon={<DnsTwoToneIcon/>} list={SalesTaxCodesList} create={SalesTaxCodesCreate} edit={SalesTaxCodesEdit} />
    <Menu.Item primaryText="Status" to="invoicestatus"  leftIcon={<AccessAlarmIcon/>} list={InvoiceStatusList} create={InvoiceStatusCreate} edit={InvoiceStatusEdit} />
    <Menu.Item primaryText="Tax Rates" to="salestaxitems"  leftIcon={<LocalAtmTwoToneIcon/>} list={SalesTaxList} create={SalesTaxCreate} edit={SalesTaxEdit} />
  </SubMenu>
  <Menu.Item primaryText="Locations" to="companies"  leftIcon={<LocationOnIcon/>} list={CompanyList} />
  <SubMenu primaryText="Service" leftIcon={<SettingsApplicationsTwoToneIcon />}>
    <Menu.Item primaryText="Packages" to="servicepackages"  leftIcon={<ListAltIcon/>} list={ServicePackagesList} create={ServicePackagesCreate} edit={ServicePackagesEdit} />
    <Menu.Item primaryText="Repair Categories" to="repaircategories"  leftIcon={<CategoryIcon/>} list={RepairCategoriesList} create={RepairCategoriesCreate} edit={RepairCategoriesEdit} />
    <Menu.Item primaryText="Repair Codes" to="repaircodes"  leftIcon={<BuildIcon/>} list={RepairCodesList} create={RepairCodesCreate} edit={RepairCodesEdit} />
    <Menu.Item primaryText="Teams" to="serviceteams"  leftIcon={<GroupIcon/>} list={ServiceTeamsList} create={ServiceTeamsCreate} edit={ServiceTeamsEdit} />
    <Menu.Item primaryText="Zones" to="workzones"  leftIcon={<ExploreIcon/>} list={WorkzonesList} create={WorkzonesCreate} edit={WorkzonesEdit} />
  </SubMenu>
  <Menu.Item primaryText="Users" to="users"  leftIcon={<PersonIcon/>} list={UserList}  edit={UserEdit} />
  <SubMenu primaryText="Work Orders" leftIcon={<BookmarksTwoToneIcon />}>
    <Menu.Item primaryText="Cancel Reasons" to="cancelreasons"  leftIcon={<CancelPresentationIcon/>} list={CancelReasonList} create={CancelReasonCreate} edit={CancelReasonEdit} />
    <Menu.Item primaryText="Categories" to="workordercategories"  leftIcon={<CategoryIcon/>} list={WorkorderCategoriesList} create={WorkorderCategoriesCreate} edit={WorkorderCategoriesEdit} />
    <Menu.Item primaryText="Category Items" to="workordercategoryitems"  leftIcon={<FormatListNumberedTwoToneIcon/>} list={WorkorderCategoryItemsList} create={WorkorderCategoryItemsCreate} edit={WorkorderCategoryItemsEdit} />
    <Menu.Item primaryText="Flag Reasons" to="flagreasons"  leftIcon={<FlagIcon/>} list={FlagReasonsList} create={FlagReasonsCreate} edit={FlagReasonsEdit} />
    <Menu.Item primaryText="Hold Reasons" to="onholdreasons"  leftIcon={<PausePresentationIcon/>} list={OnholdReasonsList} create={OnholdReasonsCreate} edit={OnholdReasonsEdit} />
    <Menu.Item primaryText="Priorities" to="priorities"  leftIcon={<PriorityHighIcon/>} list={PrioritiesList} create={PrioritiesCreate} edit={PrioritiesEdit} />
    <Menu.Item primaryText="Status" to="workorderstatus"  leftIcon={<AccessAlarmIcon/>} list={WorkorderStatusList} create={WorkorderStatusCreate} edit={WorkorderStatusEdit} />
    <Menu.Item primaryText="Types" to="workordertypes"  leftIcon={<FeaturedPlayListIcon/>} list={WorkorderTypesList} create={WorkorderTypesCreate} edit={WorkorderTypesEdit} />
  </SubMenu>
  <SubMenu primaryText="Miscellaneous" leftIcon={<MiscellaneousServicesTwoToneIcon />}>
  <Menu.Item primaryText="Payment Modes" to="paymentmodes"  leftIcon={<PaymentsTwoToneIcon/>} list={PaymentModeList} create={PaymentModeCreate} edit={PaymentModeEdit} />
  <Menu.Item primaryText="Payment Purpose" to="paymentpurpose"  leftIcon={<ShoppingBagTwoToneIcon/>}  list={PaymentPurposeList} create={PaymentPurposeCreate} edit={PaymentPurposeEdit} />
  <Menu.Item primaryText="Payment Status" to="paymentstatus"  leftIcon={<AccessAlarmIcon/>}  list={PaymentStatusList} create={PaymentStatusCreate} edit={PaymentStatusEdit} />
  <Menu.Item primaryText="Payment Terms" to="paymentterms"  leftIcon={<StyleTwoToneIcon/>}  list={PaymentTermsList} create={PaymentTermsCreate} edit={PaymentTermsEdit} />
  <Menu.Item primaryText="Subscription Status" to="subscriptionstatus"  leftIcon={<AccessAlarmIcon/>}  list={SubscriptionStatusList} create={SubscriptionStatusCreate} edit={SubscriptionStatusEdit} />
  </SubMenu>
</Menu>
);
