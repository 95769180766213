import * as React from "react";
import { BooleanField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';

export const ServicePackagesList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Display" />
            <TextField source="description" />
            <TextField source="seq" label="Sequence"/>
        </Datagrid>
    </List>
);



export const ServicePackagesCreate = props => (
    <Create title="Create a new Dispatch Center" {...props}>
        <SimpleForm>

            <TextInput source="name" label="Display" validate={required()}/>
            <TextInput source="description" />
            <TextInput source="seq" label="Sequence"/>

        </SimpleForm>
    </Create>
);


export const ServicePackagesEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
                        <TextInput source="name" label="Display" validate={required()}/>
                        <TextInput source="description" />
                        <TextInput source="seq" label="Sequence"/>
        </SimpleForm>
    </Edit>
);
