import * as React from "react";
import { BooleanField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, UrlField, ReferenceField
} from 'react-admin';

export const SuppliersList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">

            <TextField source="supplierName" label="Supplier" sortable={false}/>
            <TextField source="contact" sortable={false}/>
            <TextField source="email" sortable={false}/>
            <TextField source="phone" />
            <FunctionField label="Address" render={record => `${record.street1}, ${record.city}, ${record.state} - ${record.zip}`} />
            <UrlField source="website" />
        </Datagrid>
    </List>
);



export const SuppliersCreate = props => (
    <Create title="Create a new Supplier" {...props}>
        <SimpleForm>
            <TextInput source="supplierName" label="Supplier" validate={required()}/>

            <TextInput source="contact" validate={required()}/>
            <TextInput source="email" />
            <TextInput source="phone" label="Phone" validate={required()}/>

            <TextInput source="street1" validate={required()}/>
            <TextInput source="city" />
            <TextInput source="state" validate={required()}/>
            <TextInput source="zip" validate={required()}/>

            <TextInput source="website" validate={required()}/>
        </SimpleForm>
    </Create>
);


export const SuppliersEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="supplierName" label="Supplier" validate={required()}/>

            <TextInput source="contact" validate={required()}/>
            <TextInput source="email" />
            <TextInput source="phone" label="Phone" validate={required()}/>

            <TextInput source="street1" validate={required()}/>
            <TextInput source="city" />
            <TextInput source="state" validate={required()}/>
            <TextInput source="zip" validate={required()}/>

            <TextInput source="website" validate={required()}/>

        </SimpleForm>
    </Edit>
);
