import * as React from "react";
import { SearchInput, Pagination, ReferenceField, BooleanField, SelectInput, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';

const PostPagination = () => <Pagination rowsPerPageOptions={[50, 100]} />;
const QuickFilter = ({ label }) => {
    // const translate = useTranslate();
    return <Chip sx={{ marginBottom: 1 }} label={label} />;
};

const postFilters = [
    <SearchInput source="q" alwaysOn />,
];

export const RepairCodesList = props => (
    <List {...props} pagination={<PostPagination />} filters={postFilters} perPage={100}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Display" />
            <TextField source="description" />
            <TextField source="seq" label="Sequence"/>

            <ReferenceField label="Repair Category" source="repairCategory" reference="repaircategories" alwaysOn>
                <TextField source="name" />
            </ReferenceField>

        </Datagrid>
    </List>
);



export const RepairCodesCreate = props => (
    <Create title="Create a new Work Order" {...props}>
        <SimpleForm>

            <TextInput source="name" label="Display" validate={required()}/>
            <TextInput source="description" />
            <TextInput source="seq" label="Sequence"/>

        <ReferenceInput label="Repair Category" source="repairCategory" reference="repaircategories" alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>
        </SimpleForm>
    </Create>
);


export const RepairCodesEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
                        <TextInput source="name" label="Display" validate={required()}/>
                        <TextInput source="description" />
                        <TextInput source="seq" label="Sequence"/>

                    <ReferenceInput label="Repair Category" source="repairCategory" reference="repaircategories" alwaysOn>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
        </SimpleForm>
    </Edit>
);
