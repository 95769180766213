import * as React from "react";
import { Pagination, BooleanField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';


export const PaymentPurposeList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Display" sortable={false}/>
            <TextField source="description" sortable={false}/>
            <TextField source="code" label="Code" />
            <TextField source="seq" label="Sequence" />
        </Datagrid>
    </List>
);



export const PaymentPurposeCreate = props => (
    <Create title="Create a new Payment Porpose" {...props}>
        <SimpleForm>

            <TextInput source="name" label="Display" validate={required()}/>
            <TextInput source="description" />
            <TextInput source="code" label="Code" validate={required()}/>
            <TextInput source="seq" label="Sequence" validate={required()}/>

        </SimpleForm>
    </Create>
);


export const PaymentPurposeEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
                        <TextInput source="name" label="Display" validate={required()}/>
                        <TextInput source="description" />
                        <TextInput source="code" label="Code" validate={required()}/>
                        <TextInput source="seq" label="Sequence" validate={required()}/>
        </SimpleForm>
    </Edit>
);
