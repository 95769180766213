import * as React from "react";
import { SearchInput, Pagination, NumberField, ReferenceField, SelectInput, BooleanField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';

const PostPagination = () => <Pagination rowsPerPageOptions={[50, 100, 200]} />;

const postFilters = [
    <SearchInput  source="q" alwaysOn />,
];

export const InventoryList = props => (
    <List {...props} pagination={<PostPagination />} filters={postFilters} perPage={100}>
        <Datagrid rowClick="edit">
            <TextField source="partNumber" label="#" />
            <TextField source="partName" label="Name"/>
            <NumberField source="qty" />
            <NumberField source="customerPrice" options={{ style: 'currency', currency: 'USD' }} label="Price"/>
            <FunctionField label="Value($)" render={record => record.qty*record.customerPrice} options={{ style: 'currency', currency: 'USD' }}/>
            <ReferenceField label="Owner" source="ownerId" reference="users" alwaysOn>
                <TextField source="email" />
            </ReferenceField>
            <ReferenceField label="Dispatch Center" source="dispatchCenterId" reference="dispatchcenters" alwaysOn>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="primaryLocation" />
        </Datagrid>
    </List>
);



export const InventoryCreate = props => (
    <Create title="Create a new Dispatch Center" {...props}>
        <SimpleForm>
            <TextInput source="partNumber" label="#" />
            <TextInput source="partName" label="Name"/>
            <TextInput source="qty" />
            <ReferenceInput label="Owner" source="ownerId" reference="users" alwaysOn>
                <SelectInput optionText="email" />
            </ReferenceInput>
            <ReferenceInput label="Dispatch Center" source="dispatchCenterId" reference="dispatchcenters" alwaysOn>
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);


export const InventoryEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="partNumber" label="#" disabled/>
            <TextInput source="partName" label="Name" disabled/>
            <TextInput source="qty" disabled/>
            Stock Value <FunctionField label="Value($)" render={record => record.qty*record.customerPrice} />
            <ReferenceInput label="Owner" source="ownerId" reference="users" alwaysOn >
                <SelectInput optionText="email" disabled/>
            </ReferenceInput>
            <ReferenceInput label="Dispatch Center" source="dispatchCenterId" reference="dispatchcenters" alwaysOn>
                <SelectInput optionText="name" disabled/>
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);
